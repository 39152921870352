@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap);
:root {
  --primary-color: #f17e3c;
  --secondary-color: #576463;
  --dark-color: #0f0420;
  --cian-color: #a4c5fe;
  --background-white: #fcfcfc;
}

.text{
    text-align: center;
}
.titleMessage{
    font-family: 'Roboto Slab', serif;
    color: #edefe5 !important;
}
.containerMessage{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    

}
.logoCamping{
    margin:0!important;
    margin-top: 15px !important;
    margin-left: 20px !important;
}

.validate{
    border: solid 2px red !important;
}
