.text{
    text-align: center;
}
.titleMessage{
    font-family: 'Roboto Slab', serif;
    color: #edefe5 !important;
}
.containerMessage{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    

}
.logoCamping{
    margin:0!important;
    margin-top: 15px !important;
    margin-left: 20px !important;
}

.validate{
    border: solid 2px red !important;
}